.dashboardButton {
  border: 2px solid transparent;
  color: #1e559a;
  border-radius: 4px;
  padding: 1rem;
  display: grid;
  grid-template-columns: auto;
  grid-column-gap: 1rem;
  grid-row-gap: 0.5rem;
  transition: all 0.2s ease;
  //   grid-row-gap: 1rem;
  align-content: start;
  justify-items: center;
  // margin-bottom: 1rem;
  text-align: center;
  height: 100%;
  text-decoration: none;
  //   color: white;
  font-size: 1.25rem;
  svg {
    font-size: 2rem;
  }
  font-weight: 500;
  transform: scale(0.85);

  &:hover {
    transform: scale(0.95);
    background-color: transparent;
    color: rgba(0, 0, 0, 0.8);
  }
}

.noDecoration {
  text-decoration: none !important;
}


.container2 {
  max-width: 100%;
  margin: 50px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  text-align: center;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.contact h2 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #555;
}

.contact p {
  margin: 5px 0;
  font-size: 16px;
}

.contact a {
  color: #007BFF;
  text-decoration: none;
}

.contact a:hover {
  text-decoration: underline;
}