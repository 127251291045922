// Variable overrides

$primary: #566cb8;
$navbar-bg: #f9faff;

// $navbar-brand-minimized-width: 155px;
$navbar-brand-width: 200px;

// Sidebar

$sidebar-color: #7b8497;
$sidebar-bg: #fffe;

// Sidebar Header

$sidebar-header-bg: rgba(0, 0, 0, 0.2);

// Sidebar Form

// $sidebar-form-bg: darken($sidebar-bg, 10%) ;
$sidebar-form-color: #7b8497;
$sidebar-form-placeholder-color: rgba(255, 255, 255, 0.7);

// Sidebar Navigation

$sidebar-nav-color: #7b8497;
$sidebar-nav-title-color: #7b8497;
$sidebar-nav-link-color: #7b8497;
$sidebar-nav-link-bg: transparent;
// $sidebar-nav-link-icon-color: $gray-600 ;

$sidebar-nav-link-hover-color: $primary;
$sidebar-nav-link-hover-bg: transparent;

$sidebar-nav-link-hover-icon-color: $primary;

$sidebar-nav-link-active-color: white;
$sidebar-nav-link-active-bg: $primary;
$sidebar-nav-link-active-icon-color: white;
$sidebar-nav-link-active-borders: 0;

$sidebar-nav-link-disabled-color: darken(#7b8497, 30%);
// $sidebar-nav-link-disabled-bg: $sidebar-bg ;
// $sidebar-nav-link-disabled-icon-color: $sidebar-nav-link-icon-color ;
$sidebar-nav-link-disabled-borders: 0;

$sidebar-nav-dropdown-color: #7b8497;
$sidebar-nav-dropdown-bg: white;
$sidebar-nav-dropdown-borders: 0;
// $sidebar-nav-dropdown-indicator-color: $gray-600;
$sidebar-nav-dropdown-indicator-hover-color: $sidebar-nav-link-hover-color;

// Sidebar Footer

$sidebar-footer-bg: rgba(0, 0, 0, 0.2);

// Sidebar Minimizer

$sidebar-minimizer-bg: rgba(0, 0, 0, 0);
// $sidebar-minimizer-indicator-color: $gray-600 ;
$sidebar-minimizer-hover-bg: rgba(0, 0, 0, 0.1);
$sidebar-minimizer-hover-indicator-color: $sidebar-color;

// Footer

$footer-bg: #f9faff;
// $footer-color: $body-color !default;
