.cardContainer {
  margin-bottom: 1rem;

  .headerGrid {
    display: grid;
    grid-template-columns: 1fr auto;
  }
}

.cardContainerBody {
  display: grid;
  //   grid-auto-columns: 1fr 1fr 1fr;
  grid-template-columns: repeat(auto-fill, 250px); /* see notes below */
  grid-gap: 1rem;

  .cardImg {
    width: 100%;
    height: 150px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-color: lightgray;
    cursor: pointer;
  }
}

.marginRight {
  margin-right: 0.5rem;
}
