.buttonDisabled {
  border-color: transparent;
  text-decoration: line-through;

  &:hover {
    background-color: inherit;
    cursor: not-allowed;
    border-color: transparent;
    text-decoration: line-through !important;
  }
}

.centeredText {
  text-align: center;
}

.horizontal {
  display: flex;
}
