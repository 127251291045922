.form {
  margin: 0 auto;
  border: 1px solid #d6d8db;
  padding: 2rem;
  border-radius: 0.25rem;
  h4 {
    text-align: center;
    padding-bottom: 0.5rem;
  }
}

.submitButton {
  margin: 0 auto !important;
  display: inherit !important;
  width: 150px;
  padding: 8px !important;
}

.centered {
  margin: 0 auto;
}
