.form {
  margin: 0 auto;
  border-radius: 0.25rem;
  border: 1px solid #d6d8db;

  padding: 2rem;
  h4 {
    text-align: center;
    padding-bottom: 0.5rem;
  }
}

.submitButton {
  margin: 0 auto;
  display: inherit;
  width: 150px;
  padding: 8px;
}

.centered {
  margin: 0 auto;
}

.requiredWarning {
  font-size: 0.75rem;
  margin-bottom: 1rem;
}
