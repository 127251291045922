.centered {
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
  padding: 0 1rem;
}

.leftIcon {
  margin-right: 0.5rem;
}

.rightIcon {
  margin-left: 0.5rem;
}
