.form {
  margin: 0 auto;
  border: 1px solid lightgray;
  padding: 2rem;
  h4 {
    text-align: center;
    padding-bottom: 0.5rem;
  }
}

.submitButton {
  margin: 0 auto;
  display: inherit;
  width: 150px;
  padding: 8px;
}

.centered {
  margin: 0 auto;
}
