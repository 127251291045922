.modalWidth {
  width: fit-content;
  max-width: 1000px;
  min-width: 750px;
}

.handle {
  background-color: #f5f5f5;
  height: 16px;
  text-align: center;
  line-height: 16px;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
