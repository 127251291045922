// Here you can add other styles

.app {
  background-color: #f2f5fe;
  font-family: Lato, Arial, sans-serif !important;
  font-weight: 500;
}

.sidebar {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.075);
}

.navbar {
  box-shadow: 0 4px 30px -4px rgba(0, 51, 90, 0.1);
}

.nav-dropdown-toggle {
  color: white;
}

.sidebar-minimized .sidebar .sidebar-minimizer {
  background-color: white;

  &:hover {
    background-color: white;
  }
}

.mainPadding.container-fluid {
  padding: 30px;
  & > .card {
    margin-bottom: 0;
  }
}

.nav-dropdown-items {
  position: relative;
  &::before,
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 10px;
    background-color: transparent;
    position: absolute;
    top: -10px;
    left: 0;
    z-index: 2;
    pointer-events: none;
    box-shadow: 0 0 40px rgba(196, 202, 220, 0.82);
  }

  &::before {
    top: auto;
    bottom: -10px;
  }
}

.sidebar .nav-dropdown.open .nav-link.active {
  &:hover {
    color: white;
    background-color: $primary;
    i {
      color: white;
    }
  }
  color: white;
}

.sidebar .nav-link.active:hover {
  color: white;
  background-color: $primary;
  i {
    color: white;
  }
}

.sidebar .nav-dropdown.open .nav-link {
  &:hover {
    color: $primary;
    // background-color: $primary;
    i {
      color: $primary;
    }
  }
  // color: white;
}

// .sidebar-minimized .sidebar .nav-item:hover {
//   background-color: $primary;
// }

.sidebar-minimized .sidebar .nav-item:hover > .nav-link.active .nav-icon {
  color: white;
}

.sidebar-minimized .sidebar .nav-item:hover > .nav-link.active {
  background-color: $primary;
}
.sidebar-minimized .sidebar .nav-item:hover > .nav-link {
  background-color: white;
}

.sidebar-minimized .sidebar .nav-item:hover {
  width: 300px;
}

.sidebar-minimized .sidebar .nav-dropdown-items .nav-item {
  width: 250px;
}
.sidebar-minimized .sidebar .nav-dropdown-items .nav-item .nav-link {
  width: 250px;
}

.navbar-nav .nav-item a.active {
  color: $primary;
}
.navbar-nav .nav-item a {
  color: #73818f;
}

.sidebar-minimized .sidebar .nav-item:hover > .nav-link {
  width: inherit;
}
