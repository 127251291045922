.formContainer {
  .formHeader {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    margin-bottom: 1rem;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 10px;

    h2 {
      margin-bottom: 0rem;
    }

    .spinner {
      padding: 0rem 1rem;
    }
  }
}

.loadingModal {
  width: fit-content !important;
}

.workflowButton {
  margin-right: 0.25rem;
}
