.panelBody {
  padding: 1.5rem;
}

.tabHeading {
  // padding: 5px 5px 0 5px;
  color: #333;
  background-color: var(--primary);
  border-color: var(--primary);

  .navTabs {
    padding: 5px 5px 0 5px;
  }

  .tab {
    cursor: pointer;
    color: #333 !important;
    background-color: #d8d8d8;
    margin-right: 0.2rem;
    // color: #212529;

    &:hover {
      // color: #777;
      color: #333;

      // color: #212529;
      background-color: #ddd;
      border-color: transparent;
    }
  }
}
.tabPanel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
