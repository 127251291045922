.buttons {
  a {
    margin-right: 0.5rem;
  }
  a:last-child {
    margin-right: 0rem;
  }
}

.badge{
  background-color: #2d7dd2 !important;
  position: absolute;
  margin-right: 15px;
  right: 0;
  top: 0;
  padding: 10px;
  color: white;
  font-family: inherit;
  font-weight: bolder;
  font-size: 20px;
}