.headerGrid {
  display: grid;
  grid-template-columns: 1fr auto;
}

.cardContainerBody {
  display: grid;
  //   grid-auto-columns: 1fr 1fr 1fr;
  grid-template-columns: repeat(auto-fill, 250px); /* see notes below */
  grid-gap: 1rem;

  .cardImg {
    width: 100%;
    height: 150px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-color: white;
    cursor: pointer;
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
  }

  .programCard {
    transition: transform 0.2s ease-in-out,
      box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;

    // &:hover,
    &.active {
      background-color: #205598;
      color: white;
    }

    &:hover {
      transform: scale(0.95);
    }

    &:active {
      transform: scale(0.85);
    }
  }
}
