.alertLoader {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr auto;
  align-items: center;

  .loadingText {
    font-weight: 500;
    font-size: 1.25rem;
  }
}
