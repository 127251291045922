.tableOverflow {
  width: 100%;
  overflow-x: auto;

  tr {
    cursor: pointer;
  }
}
.centered {
  text-align: center;
}

.tablePaginator {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}
